var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { id: "calendar", fluid: "", tag: "section" } },
    [
      _c(
        "v-row",
        [
          _vm.teacher
            ? _c(
                "v-col",
                {
                  staticClass: "mt-0 mb-0 pt-0 pb-0",
                  attrs: { cols: "12", md: "12" }
                },
                [
                  _c("v-breadcrumbs", {
                    staticClass: "mt-0 mb-0 pt-0 pb-0",
                    attrs: {
                      items: [
                        { text: "Ваш центр", to: "/" },
                        { text: "Преподаватели", to: "/teachers" },
                        {
                          text: _vm.teacher.name,
                          to: "/teacher/" + _vm.teacher.id
                        },
                        { text: "Учебная сетка" }
                      ],
                      large: ""
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.teacher
            ? _c(
                "v-col",
                {
                  staticClass: "mt-0 mb-0 pt-0 pb-0 text-right",
                  attrs: { cols: "12", md: "12" }
                },
                [
                  _c("router-link", { attrs: { to: "/lessons" } }, [
                    _vm._v("\n        Перейти в общую учебную сетку\n      ")
                  ])
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            { staticClass: "mx-auto", attrs: { cols: "12", md: "12" } },
            [
              _vm.error
                ? _c(
                    "base-material-alert",
                    { attrs: { dark: "", color: "error", dismissible: "" } },
                    [_vm._v("\n        " + _vm._s(_vm.error) + "\n      ")]
                  )
                : _vm._e(),
              _c(
                "v-card",
                [
                  _c(
                    "v-sheet",
                    { staticClass: "pa-2", attrs: { flat: "" } },
                    [
                      _c("full-calendar", {
                        ref: "fullCalendar",
                        staticClass: "demo-app-calendar",
                        attrs: {
                          height: "auto",
                          lang: "ru",
                          "scheduler-license-key":
                            "GPL-My-Project-Is-Open-Source",
                          "default-view": "dayGridMonth",
                          plugins: _vm.calendarPlugins,
                          weekends: _vm.calendarWeekends,
                          "min-time": _vm.businessHours.startTime,
                          "max-time": _vm.businessHours.endTime,
                          header: {
                            left: "prev,next today",
                            center: "title",
                            right: "dayGridMonth,timeGridWeek,timeGridDay"
                          },
                          locale: _vm.locale,
                          events: _vm.timetablesEvents
                        },
                        on: {
                          eventClick: _vm.eventClick,
                          dateClick: _vm.dateClick
                        }
                      }),
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            "min-width": "200px",
                            "close-on-content-click": false,
                            "close-on-click": false,
                            activator: _vm.selectedElement,
                            "full-width": "",
                            "offset-y": ""
                          },
                          model: {
                            value: _vm.selectedOpen,
                            callback: function($$v) {
                              _vm.selectedOpen = $$v
                            },
                            expression: "selectedOpen"
                          }
                        },
                        [
                          _c(
                            "v-card",
                            {
                              attrs: { color: "ma-0 grey lighten-4", flat: "" }
                            },
                            [
                              _c(
                                "v-toolbar",
                                {
                                  attrs: {
                                    color:
                                      _vm.selectedEvent.extendedProps
                                        .colorClass,
                                    dark: ""
                                  }
                                },
                                [
                                  _c("v-toolbar-title", [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.selectedEvent.extendedProps
                                            .timetableName
                                        ) +
                                        "\n                  "
                                    ),
                                    _c("div", { staticClass: "caption" }, [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.selectedEvent.extendedProps.date
                                          ) +
                                          " " +
                                          _vm._s(
                                            _vm.selectedEvent.extendedProps
                                              .startTime
                                          ) +
                                          " — " +
                                          _vm._s(
                                            _vm.selectedEvent.extendedProps
                                              .endTime
                                          ) +
                                          "\n                  "
                                      )
                                    ])
                                  ]),
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { icon: "" },
                                      on: {
                                        click: function($event) {
                                          _vm.selectedOpen = false
                                        }
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("mdi-close")])],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-card-text",
                                [
                                  _vm.selectedEvent.extendedProps
                                    .duplicateLessonIds
                                    ? _c(
                                        "base-material-alert",
                                        {
                                          attrs: {
                                            color: "error",
                                            dark: "",
                                            dismissible: "",
                                            icon: "mdi-content-copy"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  Дубликат записи о занятии!\n                  "
                                          ),
                                          _c(
                                            "ul",
                                            _vm._l(
                                              _vm.selectedEvent.extendedProps
                                                .duplicateLessonIds,
                                              function(item) {
                                                return _c(
                                                  "li",
                                                  {
                                                    key: item,
                                                    staticClass:
                                                      "conflict-lesson",
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.toDuplicate(
                                                          item
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-uppercase"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        Занятие #" +
                                                            _vm._s(item)
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.selectedEvent.extendedProps
                                    .sameRoomLessonIds
                                    ? _c(
                                        "base-material-alert",
                                        {
                                          attrs: {
                                            color: "warning",
                                            dark: "",
                                            dismissible: "",
                                            icon: "mdi-ab-testing"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  Конфликт помещений!\n                  "
                                          ),
                                          _c(
                                            "ul",
                                            _vm._l(
                                              _vm.selectedEvent.extendedProps
                                                .sameRoomLessonIds,
                                              function(item) {
                                                return _c(
                                                  "li",
                                                  {
                                                    key: item,
                                                    staticClass:
                                                      "conflict-lesson",
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.toConflict(
                                                          item
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-uppercase"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        Занятие #" +
                                                            _vm._s(item)
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.selectedEvent.extendedProps.courseName
                                    ? _c(
                                        "div",
                                        [
                                          _c("span", { staticClass: "title" }, [
                                            _vm._v("КУРС: ")
                                          ]),
                                          _c(
                                            "v-chip",
                                            {
                                              staticClass: "ma-2",
                                              attrs: { small: "", outlined: "" }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.selectedEvent
                                                      .extendedProps.courseName
                                                  ) +
                                                  "\n                  "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.selectedEvent.extendedProps
                                    .timetableDuration
                                    ? _c(
                                        "div",
                                        [
                                          _c("span", { staticClass: "title" }, [
                                            _vm._v("ПРОДОЛЖИТЕЛЬНОСТЬ: ")
                                          ]),
                                          _c(
                                            "v-chip",
                                            {
                                              staticClass: "ma-2",
                                              attrs: { small: "", outlined: "" }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.selectedEvent
                                                      .extendedProps
                                                      .timetableDuration
                                                  ) +
                                                  "\n                  "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.selectedEvent.extendedProps.teachers
                                    ? _c(
                                        "div",
                                        [
                                          _c("span", { staticClass: "title" }, [
                                            _vm._v(
                                              "\n                    ПРЕПОДАВАТЕЛЬ:\n                  "
                                            )
                                          ]),
                                          _vm._l(
                                            _vm.selectedEvent.extendedProps
                                              .teachers,
                                            function(item) {
                                              return _c(
                                                "v-chip",
                                                {
                                                  key: item,
                                                  staticClass: "ma-2",
                                                  attrs: {
                                                    small: "",
                                                    outlined: ""
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(item) +
                                                      "\n                  "
                                                  )
                                                ]
                                              )
                                            }
                                          )
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                  _vm.selectedEvent.extendedProps.studentsCount
                                    ? _c(
                                        "div",
                                        [
                                          _c("span", { staticClass: "title" }, [
                                            _vm._v("ПРИСУТВОВАЛО: ")
                                          ]),
                                          _c(
                                            "v-chip",
                                            {
                                              staticClass: "ma-2",
                                              attrs: { small: "", outlined: "" }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.selectedEvent
                                                      .extendedProps
                                                      .studentsCount
                                                  ) +
                                                  "\n                  "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                "v-card-actions",
                                [
                                  _c("v-spacer"),
                                  _vm.selectedEvent.extendedProps.lesson
                                    ? _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            text: "",
                                            color: "secondary"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.editLesson(
                                                _vm.selectedEvent.extendedProps
                                                  .id,
                                                _vm.selectedEvent.extendedProps
                                                  .date
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("v-icon", [_vm._v("mdi-pencil")]),
                                          _vm._v(
                                            "\n                  Изменить\n                "
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  !_vm.selectedEvent.extendedProps.lesson
                                    ? _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            text: "",
                                            color: "secondary"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.createLesson(
                                                _vm.selectedEvent.extendedProps
                                                  .timetableId,
                                                _vm.selectedEvent.extendedProps
                                                  .timetableScheduleId,
                                                _vm.selectedEvent.start
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-account-multiple-check")
                                          ]),
                                          _vm._v(
                                            "\n                  Отметить посещаемость\n                "
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-dialog",
                {
                  attrs: { "max-width": "700" },
                  model: {
                    value: _vm.lessonDialog,
                    callback: function($$v) {
                      _vm.lessonDialog = $$v
                    },
                    expression: "lessonDialog"
                  }
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-title",
                        [
                          _vm.lesson.id && _vm.lesson.canceled
                            ? _c("div", [
                                _vm._v("\n              Запись об отмене "),
                                _vm.lesson.timetable &&
                                _vm.lesson.timetable.online
                                  ? _c("span", [_vm._v("онлайн ")])
                                  : _vm._e(),
                                _vm._v(
                                  "занятия #" +
                                    _vm._s(_vm.lesson.id) +
                                    "\n            "
                                )
                              ])
                            : _vm.lesson.id
                            ? _c("div", [
                                _vm._v("\n              Запись о"),
                                _vm.lesson.timetable &&
                                _vm.lesson.timetable.online
                                  ? _c("span", [_vm._v("б онлайн")])
                                  : _vm._e(),
                                _vm._v(
                                  " занятии #" +
                                    _vm._s(_vm.lesson.id) +
                                    "\n            "
                                )
                              ])
                            : _vm.lesson.canceled
                            ? _c("div", [
                                _vm._v(
                                  "\n              Добавить запись об отмене "
                                ),
                                _vm.lesson.timetable &&
                                _vm.lesson.timetable.online
                                  ? _c("span", [_vm._v("онлайн ")])
                                  : _vm._e(),
                                _vm._v("занятия\n            ")
                              ])
                            : _c("div", [
                                _vm._v("\n              Добавить запись о"),
                                _vm.lesson.timetable &&
                                _vm.lesson.timetable.online
                                  ? _c("span", [_vm._v("б онлайн")])
                                  : _vm._e(),
                                _vm._v(" занятии\n            ")
                              ]),
                          _c("v-spacer"),
                          _c(
                            "v-icon",
                            {
                              attrs: { "aria-label": "Close" },
                              on: {
                                click: function($event) {
                                  _vm.lessonDialog = false
                                }
                              }
                            },
                            [_vm._v("\n              mdi-close\n            ")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        { staticClass: "body-1 text-center pt-0" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-0",
                                  attrs: { cols: "12", md: "12" }
                                },
                                [
                                  _c(
                                    "v-form",
                                    {
                                      ref: "form",
                                      staticClass: "mt-0",
                                      attrs: { "lazy-validation": "" }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "6" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  required: "",
                                                  label:
                                                    "Дата и время начала *",
                                                  "error-messages": _vm.fieldError(
                                                    "datetime"
                                                  ),
                                                  disabled: true
                                                },
                                                model: {
                                                  value: _vm.lesson.datetime,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.lesson,
                                                      "datetime",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "lesson.datetime"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "6" } },
                                            [
                                              _c("v-checkbox", {
                                                attrs: {
                                                  required: "",
                                                  label: "Занятие отменено",
                                                  "false-value": 0,
                                                  "true-value": 1,
                                                  disabled:
                                                    _vm.lesson.id &&
                                                    _vm.lesson.clients &&
                                                    _vm.lesson.clients.length >
                                                      0,
                                                  "error-messages": _vm.fieldError(
                                                    "canceled"
                                                  )
                                                },
                                                model: {
                                                  value: _vm.lesson.canceled,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.lesson,
                                                      "canceled",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "lesson.canceled"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "6" } },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  disabled: true,
                                                  label: "Группа",
                                                  items: _vm.timetables,
                                                  "error-messages": _vm.fieldError(
                                                    "timetable_id"
                                                  ),
                                                  "item-value": "id",
                                                  "item-text": "name"
                                                },
                                                model: {
                                                  value:
                                                    _vm.lesson.timetable_id,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.lesson,
                                                      "timetable_id",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "lesson.timetable_id"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "6" } },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  disabled: true,
                                                  label: "Расписание",
                                                  items: _vm.schedules,
                                                  "error-messages": _vm.fieldError(
                                                    "timetable_schedule_id"
                                                  ),
                                                  "item-value": "id",
                                                  "item-text": "name"
                                                },
                                                model: {
                                                  value:
                                                    _vm.lesson
                                                      .timetable_schedule_id,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.lesson,
                                                      "timetable_schedule_id",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "lesson.timetable_schedule_id"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "6" } },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  label: "Помещение *",
                                                  items: _vm.rooms,
                                                  "item-value": "id",
                                                  "item-text": "name",
                                                  "error-messages": _vm.fieldError(
                                                    "room_id"
                                                  )
                                                },
                                                model: {
                                                  value: _vm.lesson.room_id,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.lesson,
                                                      "room_id",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "lesson.room_id"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "6" } },
                                            [
                                              _c("v-textarea", {
                                                attrs: {
                                                  "error-messages": _vm.fieldError(
                                                    "comment"
                                                  ),
                                                  rows: "1",
                                                  "auto-grow": "",
                                                  label: "Комментарий"
                                                },
                                                model: {
                                                  value: _vm.lesson.comment,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.lesson,
                                                      "comment",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "lesson.comment"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "12" } },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  "error-messages": _vm.fieldError(
                                                    "teachers"
                                                  ),
                                                  color: "secondary",
                                                  "item-color": "secondary",
                                                  label:
                                                    "Выбрать преподавателей",
                                                  "item-value": "id",
                                                  "item-text": "name",
                                                  multiple: "",
                                                  items: _vm.teachers
                                                },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "item",
                                                    fn: function(ref) {
                                                      var attrs = ref.attrs
                                                      var item = ref.item
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-list-item",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "mx-3 mb-2 v-sheet",
                                                                attrs: {
                                                                  "active-class":
                                                                    "secondary elevation-4 white--text",
                                                                  elevation: "0"
                                                                }
                                                              },
                                                              "v-list-item",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              "v-list-item-content",
                                                              [
                                                                _c(
                                                                  "v-list-item-title",
                                                                  {
                                                                    attrs: {
                                                                      "item-value":
                                                                        "item.id",
                                                                      "return-object":
                                                                        ""
                                                                    },
                                                                    domProps: {
                                                                      textContent: _vm._s(
                                                                        item.name
                                                                      )
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-scale-transition",
                                                              [
                                                                attrs.inputValue
                                                                  ? _c(
                                                                      "v-list-item-icon",
                                                                      {
                                                                        staticClass:
                                                                          "my-3"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          [
                                                                            _vm._v(
                                                                              "mdi-check"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e()
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ]),
                                                model: {
                                                  value: _vm.lesson.teachersIds,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.lesson,
                                                      "teachersIds",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "lesson.teachersIds"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm.lesson.timetable &&
                                      _vm.lesson.timetable.online
                                        ? _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "mb-0 pb-0 mt-0 pt-0",
                                                  attrs: { cols: "12", lg: "8" }
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      label:
                                                        "Ссылка-приглашение на конференцию",
                                                      "error-messages": _vm.fieldError(
                                                        "zoom_url"
                                                      )
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.lesson.zoom_url,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.lesson,
                                                          "zoom_url",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "lesson.zoom_url"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "mb-0 pb-0 mt-0 pt-0",
                                                  attrs: { cols: "12", lg: "4" }
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      label:
                                                        "Пароль от конференции",
                                                      "error-messages": _vm.fieldError(
                                                        "zoom_password"
                                                      )
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.lesson
                                                          .zoom_password,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.lesson,
                                                          "zoom_password",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "lesson.zoom_password"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "12" } },
                                            [
                                              _c(
                                                "base-material-card",
                                                {
                                                  staticClass: "px-5 py-3",
                                                  attrs: {
                                                    dark: "",
                                                    color: "success",
                                                    icon:
                                                      "mdi-account-multiple-check",
                                                    inline: "",
                                                    title:
                                                      "Присутствовали на занятии"
                                                  }
                                                },
                                                [
                                                  _c("v-data-table", {
                                                    attrs: {
                                                      headers:
                                                        _vm.clientsHeaders,
                                                      items: _vm.clients,
                                                      "disable-pagination": "",
                                                      "hide-default-footer": "",
                                                      "show-select": ""
                                                    },
                                                    scopedSlots: _vm._u([
                                                      {
                                                        key: "item.name",
                                                        fn: function(ref) {
                                                          var item = ref.item
                                                          return [
                                                            item.name
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "client-pointer",
                                                                    attrs: {
                                                                      title:
                                                                        "Открыть карточку клиента"
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.singleClient(
                                                                          item.id
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                              " +
                                                                        _vm._s(
                                                                          item.name
                                                                        ) +
                                                                        " #" +
                                                                        _vm._s(
                                                                          item.id
                                                                        ) +
                                                                        "\n                            "
                                                                    )
                                                                  ]
                                                                )
                                                              : item.parents
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "client-pointer",
                                                                    attrs: {
                                                                      title:
                                                                        "Открыть карточку клиента"
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.singleClient(
                                                                          item.id
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                              " +
                                                                        _vm._s(
                                                                          item.parents
                                                                        ) +
                                                                        " #" +
                                                                        _vm._s(
                                                                          item.id
                                                                        ) +
                                                                        "\n                            "
                                                                    )
                                                                  ]
                                                                )
                                                              : _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "client-pointer",
                                                                    attrs: {
                                                                      title:
                                                                        "Открыть карточку клиента"
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.singleClient(
                                                                          item.id
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                              #" +
                                                                        _vm._s(
                                                                          item.id
                                                                        ) +
                                                                        "\n                            "
                                                                    )
                                                                  ]
                                                                )
                                                          ]
                                                        }
                                                      },
                                                      {
                                                        key:
                                                          "item.activeSubscriptions",
                                                        fn: function(ref) {
                                                          var item = ref.item
                                                          return [
                                                            !_vm.isCheckedClient(
                                                              item
                                                            )
                                                              ? _c(
                                                                  "div",
                                                                  [
                                                                    _vm.isMultiplyActiveSubscriptions(
                                                                      item.activeSubscriptions
                                                                    )
                                                                      ? _c(
                                                                          "base-material-alert",
                                                                          {
                                                                            staticClass:
                                                                              "pt-1 pb-1 mt-1 mb-1",
                                                                            attrs: {
                                                                              color:
                                                                                "pink darken-1"
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-icon",
                                                                              {
                                                                                staticClass:
                                                                                  "mr-2"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                  mdi-alert-decagram-outline\n                                "
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "font-weight-light caption"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "На данное занятие доступны несколько абонементов.\n                                  Занятие будет отмечено в первом по приоритету абонементе #" +
                                                                                    _vm._s(
                                                                                      item
                                                                                        .activeSubscriptions[0]
                                                                                        .id
                                                                                    ) +
                                                                                    ".\n                                  Более приоритетным является абонемент у которого остались неиспользованные занятия и дата окончания наступает раньше."
                                                                                )
                                                                              ]
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e()
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            _vm._l(
                                                              item.activeSubscriptions,
                                                              function(
                                                                subscription,
                                                                index
                                                              ) {
                                                                return _c(
                                                                  "v-chip",
                                                                  {
                                                                    key:
                                                                      subscription.id,
                                                                    staticClass:
                                                                      "abo-chip",
                                                                    class: {
                                                                      "abo-chip-first":
                                                                        index ===
                                                                        0,
                                                                      "abo-chip-other":
                                                                        index !==
                                                                        0
                                                                    },
                                                                    attrs: {
                                                                      color:
                                                                        subscription
                                                                          .extendedProps
                                                                          .colorClass,
                                                                      title:
                                                                        "Открыть абонемент"
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.singleSubscription(
                                                                          subscription.id
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                              [" +
                                                                        _vm._s(
                                                                          subscription.start_date
                                                                        ) +
                                                                        ":" +
                                                                        _vm._s(
                                                                          subscription.end_date
                                                                        ) +
                                                                        "] [#" +
                                                                        _vm._s(
                                                                          subscription.id
                                                                        ) +
                                                                        "] " +
                                                                        _vm._s(
                                                                          subscription.title
                                                                        ) +
                                                                        "\n                            "
                                                                    )
                                                                  ]
                                                                )
                                                              }
                                                            )
                                                          ]
                                                        }
                                                      },
                                                      {
                                                        key: "footer",
                                                        fn: function() {
                                                          return [
                                                            _c(
                                                              "v-card-text",
                                                              [
                                                                _c(
                                                                  "v-row",
                                                                  {
                                                                    attrs: {
                                                                      align:
                                                                        "center",
                                                                      justify:
                                                                        "end"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "strong",
                                                                      {
                                                                        staticClass:
                                                                          "title"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Присутствовали"
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm.lesson
                                                                      .clients
                                                                      ? _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "display-1 font-weight-light ml-12"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                  " +
                                                                                _vm._s(
                                                                                  _vm
                                                                                    .lesson
                                                                                    .clients
                                                                                    .length
                                                                                ) +
                                                                                "\n                                "
                                                                            )
                                                                          ]
                                                                        )
                                                                      : _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "display-1 font-weight-light ml-12"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                  0\n                                "
                                                                            )
                                                                          ]
                                                                        )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        },
                                                        proxy: true
                                                      }
                                                    ]),
                                                    model: {
                                                      value: _vm.lesson.clients,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.lesson,
                                                          "clients",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "lesson.clients"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "ma-1",
                              attrs: { rounded: "", "min-width": "100" },
                              on: {
                                click: function($event) {
                                  _vm.lessonDialog = false
                                }
                              }
                            },
                            [_vm._v("\n              Отмена\n            ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "ma-1",
                              attrs: {
                                rounded: "",
                                color: "secondary",
                                "min-width": "100"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.saveLesson()
                                }
                              }
                            },
                            [_vm._v("\n              Сохранить\n            ")]
                          ),
                          _vm.lesson.id
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "ma-1",
                                  attrs: {
                                    rounded: "",
                                    color: "error",
                                    "min-width": "100"
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.confirmDialog = true
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              Удалить\n            "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-dialog",
                {
                  attrs: { "max-width": "290" },
                  model: {
                    value: _vm.confirmDialog,
                    callback: function($$v) {
                      _vm.confirmDialog = $$v
                    },
                    expression: "confirmDialog"
                  }
                },
                [
                  _c(
                    "v-card",
                    [
                      _c("v-card-title", { staticClass: "headline" }, [
                        _vm._v("\n            Удалить запись?\n          ")
                      ]),
                      _c("v-card-text", [
                        _vm._v(
                          "\n            Запись будет удалена без возможности восстановления.\n          "
                        )
                      ]),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "darken-1", text: "" },
                              on: {
                                click: function($event) {
                                  _vm.confirmDialog = false
                                }
                              }
                            },
                            [_vm._v("\n              Отмена\n            ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "error darken-1", text: "" },
                              on: {
                                click: function($event) {
                                  return _vm.removeLesson(_vm.lesson.id)
                                }
                              }
                            },
                            [_vm._v("\n              Удалить\n            ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }