exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../node_modules/css-loader/index.js??ref--8-oneOf-1-1!../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../node_modules/postcss-loader/src/index.js??ref--8-oneOf-1-2!@fullcalendar/core/main.css"), "");
exports.i(require("-!../../node_modules/css-loader/index.js??ref--8-oneOf-1-1!../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../node_modules/postcss-loader/src/index.js??ref--8-oneOf-1-2!@fullcalendar/daygrid/main.css"), "");
exports.i(require("-!../../node_modules/css-loader/index.js??ref--8-oneOf-1-1!../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../node_modules/postcss-loader/src/index.js??ref--8-oneOf-1-2!@fullcalendar/timegrid/main.css"), "");
exports.i(require("-!../../node_modules/css-loader/index.js??ref--8-oneOf-1-1!../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../node_modules/postcss-loader/src/index.js??ref--8-oneOf-1-2!@fullcalendar/timeline/main.css"), "");
exports.i(require("-!../../node_modules/css-loader/index.js??ref--8-oneOf-1-1!../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../node_modules/postcss-loader/src/index.js??ref--8-oneOf-1-2!@fullcalendar/resource-timeline/main.css"), "");

// module
exports.push([module.id, "/** Ripples */\n/** Elements */\n.v-toolbar__title {\n  text-transform: capitalize;\n}\na.fc-event {\n  color: white !important;\n}\n.fc-sat, .fc-sun {\n  background: rgba(255, 0, 32, 0.07);\n}\n.conflict-lesson {\n  cursor: pointer;\n}\n.fc-icon {\n  line-height: 0.6;\n}\n.fc-button {\n  background-color: #9c27b0 !important;\n  border-color: #9c27b0 !important;\n  padding: 10px 15px !important;\n  margin-bottom: 5px;\n}\n.client-pointer {\n  cursor: pointer;\n}\n.client-pointer:hover {\n  text-decoration: underline;\n}\n.abo-chip {\n  height: auto !important;\n  margin-bottom: 1px !important;\n}\n.abo-chip-other {\n  opacity: 0.5;\n}", ""]);

// exports
