<template>
  <v-container
    id="calendar"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        v-if="teacher"
        cols="12"
        md="12"
        class="mt-0 mb-0 pt-0 pb-0"
      >
        <v-breadcrumbs
          class="mt-0 mb-0 pt-0 pb-0"
          :items="[{text: 'Ваш центр', to: '/'}, {text: 'Преподаватели', to: '/teachers'}, {text: teacher.name, to: `/teacher/${teacher.id}`} , {text: 'Учебная сетка'}]"
          large
        />
      </v-col>
      <v-col
        v-if="teacher"
        cols="12"
        md="12"
        class="mt-0 mb-0 pt-0 pb-0 text-right"
      >
        <router-link to="/lessons">
          Перейти в общую учебную сетку
        </router-link>
      </v-col>
      <v-col
        cols="12"
        md="12"
        class="mx-auto"
      >
        <base-material-alert
          v-if="error"
          dark
          color="error"
          dismissible
        >
          {{ error }}
        </base-material-alert>
        <v-card>
          <v-sheet
            flat
            class="pa-2"
          >
            <full-calendar
              ref="fullCalendar"
              height="auto"
              class="demo-app-calendar"
              lang="ru"
              scheduler-license-key="GPL-My-Project-Is-Open-Source"
              default-view="dayGridMonth"
              :plugins="calendarPlugins"
              :weekends="calendarWeekends"
              :min-time="businessHours.startTime"
              :max-time="businessHours.endTime"
              :header="{
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay'
              }"
              :locale="locale"
              :events="timetablesEvents"
              @eventClick="eventClick"
              @dateClick="dateClick"
            />
            <v-menu
              v-model="selectedOpen"
              min-width="200px"
              :close-on-content-click="false"
              :close-on-click="false"
              :activator="selectedElement"
              full-width
              offset-y
            >
              <v-card
                color="ma-0 grey lighten-4"
                flat
              >
                <v-toolbar
                  :color="selectedEvent.extendedProps.colorClass"
                  dark
                >
                  <v-toolbar-title>
                    {{ selectedEvent.extendedProps.timetableName }}
                    <div class="caption">
                      {{ selectedEvent.extendedProps.date }} {{ selectedEvent.extendedProps.startTime }} &mdash; {{
                        selectedEvent.extendedProps.endTime }}
                    </div>
                  </v-toolbar-title>
                  <v-spacer />
                  <v-btn
                    icon
                    @click="selectedOpen = false"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  <base-material-alert
                    v-if="selectedEvent.extendedProps.duplicateLessonIds"
                    color="error"
                    dark
                    dismissible
                    icon="mdi-content-copy"
                  >
                    Дубликат записи о занятии!
                    <ul>
                      <li
                        v-for="item in selectedEvent.extendedProps.duplicateLessonIds"
                        :key="item"
                        class="conflict-lesson"
                        @click="toDuplicate(item)"
                      >
                        <span
                          class="text-uppercase"
                        >
                          Занятие #{{ item }}</span>
                      </li>
                    </ul>
                  </base-material-alert>
                  <base-material-alert
                    v-if="selectedEvent.extendedProps.sameRoomLessonIds"
                    color="warning"
                    dark
                    dismissible
                    icon="mdi-ab-testing"
                  >
                    Конфликт помещений!
                    <ul>
                      <li
                        v-for="item in selectedEvent.extendedProps.sameRoomLessonIds"
                        :key="item"
                        class="conflict-lesson"
                        @click="toConflict(item)"
                      >
                        <span
                          class="text-uppercase"
                        >
                          Занятие #{{ item }}</span>
                      </li>
                    </ul>
                  </base-material-alert>

                  <div v-if="selectedEvent.extendedProps.courseName">
                    <span class="title">КУРС: </span>
                    <v-chip
                      small
                      class="ma-2"
                      outlined
                    >
                      {{ selectedEvent.extendedProps.courseName }}
                    </v-chip>
                  </div>

                  <div v-if="selectedEvent.extendedProps.timetableDuration">
                    <span class="title">ПРОДОЛЖИТЕЛЬНОСТЬ: </span>
                    <v-chip
                      small
                      class="ma-2"
                      outlined
                    >
                      {{ selectedEvent.extendedProps.timetableDuration }}
                    </v-chip>
                  </div>

                  <div v-if="selectedEvent.extendedProps.teachers">
                    <span class="title">
                      ПРЕПОДАВАТЕЛЬ:
                    </span>
                    <v-chip
                      v-for="item in selectedEvent.extendedProps.teachers"
                      :key="item"
                      small
                      class="ma-2"
                      outlined
                    >
                      {{ item }}
                    </v-chip>
                  </div>

                  <div v-if="selectedEvent.extendedProps.studentsCount">
                    <span class="title">ПРИСУТВОВАЛО: </span>
                    <v-chip
                      small
                      class="ma-2"
                      outlined
                    >
                      {{ selectedEvent.extendedProps.studentsCount }}
                    </v-chip>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    v-if="selectedEvent.extendedProps.lesson"
                    text
                    color="secondary"
                    @click="editLesson(selectedEvent.extendedProps.id, selectedEvent.extendedProps.date)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                    Изменить
                  </v-btn>
                  <v-btn
                    v-if="!selectedEvent.extendedProps.lesson"
                    text
                    color="secondary"
                    @click="createLesson(selectedEvent.extendedProps.timetableId, selectedEvent.extendedProps.timetableScheduleId, selectedEvent.start)"
                  >
                    <v-icon>mdi-account-multiple-check</v-icon>
                    Отметить посещаемость
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-sheet>
        </v-card>

        <v-dialog
          v-model="lessonDialog"
          max-width="700"
        >
          <v-card>
            <v-card-title>
              <div v-if="lesson.id && lesson.canceled">
                Запись об отмене <span v-if="lesson.timetable && lesson.timetable.online">онлайн </span>занятия #{{ lesson.id }}
              </div>
              <div v-else-if="lesson.id">
                Запись о<span v-if="lesson.timetable && lesson.timetable.online">б онлайн</span> занятии #{{ lesson.id }}
              </div>
              <div v-else-if="lesson.canceled">
                Добавить запись об отмене <span v-if="lesson.timetable && lesson.timetable.online">онлайн </span>занятия
              </div>
              <div v-else>
                Добавить запись о<span v-if="lesson.timetable && lesson.timetable.online">б онлайн</span> занятии
              </div>
              <v-spacer />
              <v-icon
                aria-label="Close"
                @click="lessonDialog = false"
              >
                mdi-close
              </v-icon>
            </v-card-title>

            <v-card-text class="body-1 text-center pt-0">
              <v-row>
                <v-col
                  class="pt-0"
                  cols="12"
                  md="12"
                >
                  <v-form
                    ref="form"
                    class="mt-0"
                    lazy-validation
                  >
                    <v-row>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="lesson.datetime"
                          required
                          label="Дата и время начала *"
                          :error-messages="fieldError('datetime')"
                          :disabled="true"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-checkbox
                          v-model="lesson.canceled"
                          required
                          label="Занятие отменено"
                          :false-value="0"
                          :true-value="1"
                          :disabled="lesson.id && lesson.clients && lesson.clients.length > 0"
                          :error-messages="fieldError('canceled')"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-select
                          v-model="lesson.timetable_id"
                          :disabled="true"
                          label="Группа"
                          :items="timetables"
                          :error-messages="fieldError('timetable_id')"
                          item-value="id"
                          item-text="name"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-select
                          v-model="lesson.timetable_schedule_id"
                          :disabled="true"
                          label="Расписание"
                          :items="schedules"
                          :error-messages="fieldError('timetable_schedule_id')"
                          item-value="id"
                          item-text="name"
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-select
                          v-model="lesson.room_id"
                          label="Помещение *"
                          :items="rooms"
                          item-value="id"
                          item-text="name"
                          :error-messages="fieldError('room_id')"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-textarea
                          v-model="lesson.comment"
                          :error-messages="fieldError('comment')"
                          rows="1"
                          auto-grow
                          label="Комментарий"
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col
                        cols="12"
                        md="12"
                      >
                        <v-select
                          v-model="lesson.teachersIds"
                          :error-messages="fieldError('teachers')"
                          color="secondary"
                          item-color="secondary"
                          label="Выбрать преподавателей"
                          item-value="id"
                          item-text="name"
                          multiple
                          :items="teachers"
                        >
                          <template v-slot:item="{ attrs, item, on }">
                            <v-list-item
                              v-bind="attrs"
                              active-class="secondary elevation-4 white--text"
                              class="mx-3 mb-2 v-sheet"
                              elevation="0"
                              v-on="on"
                            >
                              <v-list-item-content>
                                <v-list-item-title
                                  item-value="item.id"
                                  return-object
                                  v-text="item.name"
                                />
                              </v-list-item-content>

                              <v-scale-transition>
                                <v-list-item-icon
                                  v-if="attrs.inputValue"
                                  class="my-3"
                                >
                                  <v-icon>mdi-check</v-icon>
                                </v-list-item-icon>
                              </v-scale-transition>
                            </v-list-item>
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>

                    <v-row v-if="lesson.timetable && lesson.timetable.online">
                      <v-col
                        cols="12"
                        lg="8"
                        class="mb-0 pb-0 mt-0 pt-0"
                      >
                        <v-text-field
                          v-model="lesson.zoom_url"
                          label="Ссылка-приглашение на конференцию"
                          :error-messages="fieldError('zoom_url')"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        lg="4"
                        class="mb-0 pb-0 mt-0 pt-0"
                      >
                        <v-text-field
                          v-model="lesson.zoom_password"
                          label="Пароль от конференции"
                          :error-messages="fieldError('zoom_password')"
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col
                        cols="12"
                        md="12"
                      >
                        <base-material-card
                          dark
                          color="success"
                          icon="mdi-account-multiple-check"
                          inline
                          title="Присутствовали на занятии"
                          class="px-5 py-3"
                        >
                          <v-data-table
                            v-model="lesson.clients"
                            :headers="clientsHeaders"
                            :items="clients"
                            disable-pagination
                            hide-default-footer
                            show-select
                          >
                            <template v-slot:item.name="{ item }">
                              <span
                                v-if="item.name"
                                title="Открыть карточку клиента"
                                class="client-pointer"
                                @click="singleClient(item.id)"
                              >
                                {{ item.name }} #{{ item.id }}
                              </span>
                              <span
                                v-else-if="item.parents"
                                title="Открыть карточку клиента"
                                class="client-pointer"
                                @click="singleClient(item.id)"
                              >
                                {{ item.parents }} #{{ item.id }}
                              </span>
                              <span
                                v-else
                                title="Открыть карточку клиента"
                                class="client-pointer"
                                @click="singleClient(item.id)"
                              >
                                #{{ item.id }}
                              </span>
                            </template>

                            <template v-slot:item.activeSubscriptions="{ item }">
                              <div v-if="!isCheckedClient(item)">
                                <base-material-alert
                                  v-if="isMultiplyActiveSubscriptions(item.activeSubscriptions)"
                                  color="pink darken-1"
                                  class="pt-1 pb-1 mt-1 mb-1"
                                >
                                  <v-icon class="mr-2">
                                    mdi-alert-decagram-outline
                                  </v-icon>
                                  <span class="font-weight-light caption">На данное занятие доступны несколько абонементов.
                                    Занятие будет отмечено в первом по приоритету абонементе #{{ item.activeSubscriptions[0].id }}.
                                    Более приоритетным является абонемент у которого остались неиспользованные занятия и дата окончания наступает раньше.</span>
                                </base-material-alert>
                              </div>

                              <v-chip
                                v-for="(subscription, index) in item.activeSubscriptions"
                                :key="subscription.id"
                                class="abo-chip"
                                :class="{ 'abo-chip-first': index===0, 'abo-chip-other': index !== 0}"
                                :color="subscription.extendedProps.colorClass"
                                title="Открыть абонемент"
                                @click="singleSubscription(subscription.id)"
                              >
                                [{{ subscription.start_date }}:{{ subscription.end_date }}] [#{{ subscription.id }}] {{ subscription.title }}
                              </v-chip>
                            </template>

                            <template v-slot:footer>
                              <v-card-text>
                                <v-row
                                  align="center"
                                  justify="end"
                                >
                                  <strong class="title">Присутствовали</strong>

                                  <div
                                    v-if="lesson.clients"
                                    class="display-1 font-weight-light ml-12"
                                  >
                                    {{ lesson.clients.length }}
                                  </div>
                                  <div
                                    v-else
                                    class="display-1 font-weight-light ml-12"
                                  >
                                    0
                                  </div>
                                </v-row>
                              </v-card-text>
                            </template>
                          </v-data-table>
                        </base-material-card>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-col>
              </v-row>

              <v-btn
                class="ma-1"
                rounded
                min-width="100"
                @click="lessonDialog = false"
              >
                Отмена
              </v-btn>

              <v-btn
                class="ma-1"
                rounded
                color="secondary"
                min-width="100"
                @click="saveLesson()"
              >
                Сохранить
              </v-btn>

              <v-btn
                v-if="lesson.id"
                class="ma-1"
                rounded
                color="error"
                min-width="100"
                @click="confirmDialog = true"
              >
                Удалить
              </v-btn>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="confirmDialog"
          max-width="290"
        >
          <v-card>
            <v-card-title class="headline">
              Удалить запись?
            </v-card-title>

            <v-card-text>
              Запись будет удалена без возможности восстановления.
            </v-card-text>

            <v-card-actions>
              <v-spacer />

              <v-btn
                color="darken-1"
                text
                @click="confirmDialog = false"
              >
                Отмена
              </v-btn>

              <v-btn
                color="error darken-1"
                text
                @click="removeLesson(lesson.id)"
              >
                Удалить
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import FullCalendar from '@fullcalendar/vue'
  import dayGridPlugin from '@fullcalendar/daygrid'
  import timeGridPlugin from '@fullcalendar/timegrid'
  import interactionPlugin from '@fullcalendar/interaction'
  import ruLocale from '@fullcalendar/core/locales/ru'
  import moment from 'moment'
  import clientsApi from '../services/ClientsApi'
  import timetableApi from '../services/TimetablesApi'
  import roomsApi from '../services/RoomsApi'
  import lessonsApi from '../services/LessonsApi'
  import teachersApi from '../services/TeachersApi'
  import scheduleApi from '../services/SchedulesApi'

  export default {
    filters: {
      formatDate: function (date) {
        return moment(date).format('YYYY-MM-DD')
      },
      formatDateFromUnixTime: function (unixtime) {
        return moment.unix(unixtime).format('YYYY-MM-DD HH:mm')
      },
    },
    components: {
      FullCalendar, // make the <FullCalendar> tag available
    },
    props: ['teacherId'],
    data: function () {
      return {
        timetablesEvents: (fetchInfo, successCallback, failureCallback) => {
          let start = moment(fetchInfo.startStr).format('YYYY-MM-DD')
          let end = moment(fetchInfo.endStr).format('YYYY-MM-DD')
          if (this.teacherId) {
            timetableApi.fetchTeacherTimetablesByDates(this.teacherId, start, end)
              .then(response => {
                successCallback(response)
              })
              .catch(error => {
                failureCallback(error)
              })
          } else {
            timetableApi.fetchTimetablesByDates(start, end)
              .then(response => {
                successCallback(response)
              })
              .catch(error => {
                failureCallback(error)
              })
          }
        },
        eventDataTransform: function (event) {
          event.end = moment(event.end).add(1, 'days').format('YYYY-MM-DD')
          return event
        },
        businessHours: {
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
          startTime: '08:00',
          endTime: '23:00',
        },
        title: null,
        onlyWithEvents: false,
        calendarPlugins: [ // plugins must be defined in the JS
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
        ],
        locale: ruLocale,
        calendarWeekends: true,
        calendarEvents: [ // initial event data
          { title: 'Event Now', start: new Date() },
        ],

        selectedEvent: { extendedProps: { colorClass: null, clientName: null } },
        selectedElement: null,
        selectedOpen: false,

        timetables: [{ id: null, name: ' - не выбрано - ', tag: null }],
        schedules: [{ id: null, name: ' - не выбрано - ', tag: null }],

        error: null,
        loading: true,
        fieldsErrors: [],
        fieldError (fieldName) {
          let errors = []
          if (this.fieldsErrors) {
            this.fieldsErrors.filter(field => {
              if (field.field === fieldName) {
                errors.push(field.message)
              }
            })
          }
          return errors
        },

        lessonDialog: false,
        confirmDialog: false,

        clients: [],
        lesson: {
          datetime: null,
          room_id: null,
          teachers: [],
          timetable_id: null,
          comment: null,
          clients: [],
        },
        rooms: [],
        teachers: [],

        clientsHeaders: [
          {
            sortable: true,
            text: 'Имя',
            value: 'name',
          },
          {
            sortable: false,
            text: 'Абонемент',
            value: 'activeSubscriptions',
          },
        ],

        teacher: null,
      }
    },
    mounted () {
      document.title = 'Учебная сетка | ' + process.env.VUE_APP_NAME
      moment.locale('ru')

      if (this.teacherId) {
        teachersApi
          .fetchTeacher(this.teacherId)
          .then(response => {
            this.teacher = response
            document.title = this.teacher.name + ' | Учебная сетка | ' + process.env.VUE_APP_NAME
          }).catch(error => {
            this.error = this.pretty(error.response.data)
            this.loading = false
          })
      }

      timetableApi
        .fetchActiveTimetables()
        .then(response => {
          this.timetables = this.timetables.concat(response)
        })
        .catch(error => {
          this.error = this.pretty(error.response.data)
        })

      roomsApi
        .fetchRooms()
        .then(response => {
          this.rooms = response
        })
        .catch(error => {
          this.error = this.pretty(error.response.data)
        })

      teachersApi
        .fetchActiveTeachers()
        .then(response => {
          this.teachers = response
        })
        .catch(error => {
          this.error = this.pretty(error.response.data)
        })
    },
    methods: {
      eventClick (info) {
        this.selectedEvent = info.event
        this.selectedElement = info.jsEvent.target
        if (this.selectedEvent.extendedProps.lesson) {
          if (this.selectedEvent.extendedProps.sameRoomLessonIds || this.selectedEvent.extendedProps.duplicateLessonIds) {
            const open = () => {
              setTimeout(() => this.selectedOpen = true, 10)
            }
            if (this.selectedOpen) {
              this.selectedOpen = false
              setTimeout(open, 10)
            } else {
              open()
            }
          } else {
            this.editLesson(this.selectedEvent.extendedProps.id, this.selectedEvent.extendedProps.date)
          }
        } else {
          this.createLesson(this.selectedEvent.extendedProps.timetableId, this.selectedEvent.extendedProps.timetableScheduleId, this.selectedEvent.start)
        }
      },
      dateClick (arg) {
      },
      removeLesson (lessonId) {
        this.loading = true
        this.confirmDialog = false
        lessonsApi
          .deleteLesson(lessonId)
          .then(response => {
            this.lessonDialog = false
            this.selectedOpen = false
            this.loading = false
            this.$refs.fullCalendar.getApi().refetchEvents()
          })
          .catch(error => {
            let data = error.response.data
            if (error.response.status === 422) {
              this.fieldsErrors = data
            } else {
              this.error = this.pretty(data)
            }
            this.loading = false
            this.$refs.fullCalendar.getApi().refetchEvents()
          })
      },
      editLesson (lessonId, lessonDate) {
        this.loading = true
        lessonsApi
          .fetchLesson(lessonId)
          .then(response => {
            this.lesson = response
            this.schedules = [this.lesson.schedule]
            if (this.lesson.teachersIds) {
              this.lesson.teachersIds = this.lesson.teachersIds.map(function (item) {
                return parseInt(item, 10)
              })
            }
            this.lessonDialog = true
            clientsApi.fetchClientsByTimetableId(this.lesson.timetable_id, lessonDate)
              .then(response => {
                let unprocessedClients = response

                let clses = this.lesson.clses
                this.clients = []

                // отображаем учеников с купленными абонементами или уже отмеченных
                for (var i = 0, len = unprocessedClients.length; i < len; i++) {
                  let client = unprocessedClients[i]
                  let clientChecked = this.lesson.clients.some(elem => elem.id === client.id)
                  let linkedCLS = clses.filter((item) => item.student_id === client.id)
                  if (linkedCLS.length > 0 && !client.activeSubscriptions.find(item => item.id === linkedCLS[0].subscription.id)) {
                    client.activeSubscriptions = []
                    client.activeSubscriptions.push(linkedCLS[0].subscription)
                  }
                  if (client.activeSubscriptions.length > 0 || clientChecked) {
                    this.clients.push(client)
                  }
                }
              })
              .catch(error => {
                let data = error.response.data
                if (error.response.status === 422) {
                  this.fieldsErrors = data
                } else {
                  this.error = this.pretty(data)
                }
                this.loading = false
              })
          })
      },
      editClient (clientId) {
        this.$router.push('/client/' + clientId)
      },
      createLesson (timetableId, timetableScheduleId, dateTime) {
        this.loading = true

        scheduleApi.fetchSchedulesByTimetableId(timetableId)
          .then(response => {
            this.schedules = response
          })
          .catch(error => {
            let data = error.response.data
            if (error.response.status === 422) {
              this.fieldsErrors = data
            } else {
              this.error = this.pretty(data)
            }
            this.loading = false
          })

        clientsApi.fetchClientsByTimetableId(timetableId, moment(dateTime).format('YYYY-MM-DD'))
          .then(response => {
            this.clients = []
            // отображаем только учеников с купленными абонементами
            for (var i = 0, len = response.length; i < len; i++) {
              let client = response[i]
              if (client.activeSubscriptions.length > 0) {
                this.clients.push(client)
              }
            }

            this.lesson = {}
            this.lesson.clients = []
            this.lesson.timetable_id = timetableId
            this.lesson.timetable_schedule_id = timetableScheduleId
            this.lesson.datetime = moment(dateTime).format('YYYY-MM-DD HH:mm:ss')

            let timetable = this.timetables.find(e => e.id === +timetableId)
            if (timetable) {
              if (timetable.room_id) this.lesson.room_id = timetable.room_id
              if (timetable.teacher_id) this.lesson.teachersIds = [timetable.teacher_id]
              this.lesson.timetable = timetable
            }

            this.lessonDialog = true
            this.loading = false
          })
          .catch(error => {
            let data = error.response.data
            if (error.response.status === 422) {
              this.fieldsErrors = data
            } else {
              this.error = this.pretty(data)
            }
            this.loading = false
          })
      },
      saveLesson () {
        this.loading = true
        if (this.lesson.id) {
          this.error = null
          this.fieldsErrors = []
          this.loading = true
          this.lesson.clientsIds = this.lesson.clients.map(c => c.id)

          lessonsApi
            .updateLesson(this.lesson)
            .then(response => {
              this.lesson = response
              this.loading = false
              this.$refs.fullCalendar.getApi().refetchEvents()
              this.lessonDialog = false
            })
            .catch(error => {
              let data = error.response.data
              if (error.response.status === 422) {
                this.fieldsErrors = data
              } else {
                this.error = this.pretty(data)
              }
              this.$refs.fullCalendar.getApi().refetchEvents()
              this.loading = false
            })
        } else {
          this.lesson.clientsIds = this.lesson.clients.map(c => c.id)
          lessonsApi
            .createLesson(this.lesson)
            .then(response => {
              this.lesson = response
              this.lessonDialog = false
              this.$refs.fullCalendar.getApi().refetchEvents()
              this.loading = false
            })
            .catch(error => {
              let data = error.response.data
              if (error.response.status === 422) {
                this.fieldsErrors = data
              } else {
                this.error = this.pretty(data)
              }
              this.$refs.fullCalendar.getApi().refetchEvents()
              this.loading = false
            })
        }
      },

      isMultiplyActiveSubscriptions (activeSubscriptions) {
        return activeSubscriptions.length > 1
      },

      singleClient (id) {
        this.$router.push('/client/' + id)
      },

      singleSubscription (id) {
        this.$router.push('/subscription/' + id)
      },

      pretty (value) {
        if (value.message) {
          return value.message
        }
        return JSON.stringify(value, null, 2)
      },

      isCheckedClient (client) {
        if (!this.lesson || !this.lesson.clients || this.lesson.clients.length === 0) return false
        return this.lesson.clients.some(elem => elem.id === client.id)
      },
    },
  }

</script>

<style lang="scss">
  // you must include each plugins' css
  // paths prefixed with ~ signify node_modules
  @import '~@fullcalendar/core/main.css';
  @import '~@fullcalendar/daygrid/main.css';
  @import '~@fullcalendar/timegrid/main.css';
  @import '~@fullcalendar/timeline/main.css';
  @import '~@fullcalendar/resource-timeline/main.css';

  .v-toolbar__title {
    text-transform: capitalize;
  }

  a.fc-event {
    color: white !important;
  }

  .fc-sat, .fc-sun {
    background: rgba(255, 0, 32, 0.07);
  }

  .conflict-lesson {
    cursor: pointer;
  }

  .fc-icon {
    line-height: 0.6;
  }

  .fc-button {
    background-color: #9c27b0 !important;
    border-color: #9c27b0 !important;
    padding: 10px 15px !important;
    margin-bottom: 5px;
  }

  .client-pointer {
    cursor: pointer;
  }

  .client-pointer:hover {
    text-decoration: underline;
  }

  .abo-chip {
    height: auto !important;
    margin-bottom: 1px !important;
  }

  .abo-chip-other {
    opacity: 0.5;
  }
</style>
